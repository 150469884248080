import React from 'react'
import EmailIcon from '@mui/icons-material/Email';
import DownloadIcon from '@mui/icons-material/Download';
import '../styles/Bienvenida.css';

function Bienvenida() {

  return (
    <div className='home'>
        <div className='about'>
        <h2>Hola, Soy Lilian Sanhueza</h2>
          <div className='prompt '> <p>
                            Soy una profesional con amplia experiencia y conocimientos en el área de
                administración de personas, recursos humanos, remuneraciones y  legislación laboral. 
                Debido a mi interés por las personas y sus problemáticas
                decidí continuar especializándome y actualmente estoy cursando la carrera de Derecho.
                        </p>
                        Si tienes alguna duda o deseas comunicarte conmigo escribeme a mi correo.
                        <p className='email'> <EmailIcon onClick={() => window.open('mailto:contacto@liliansanhueza.com', '_blank')}/></p>
                        Si quieres saber más de mi, revisa mi curriculum a continuación.
                        <p className='curriculum'>
                      
                        <a   href='cv.pdf' download='Curriculum Lilian Sanhueza.pdf'>
                      <DownloadIcon /> 
                        </a>
                        </p>
                      
                        </div>
        </div>
        <div className='skills'>
          <h1>Habilidades</h1>
          <ol className='list'>
            <li className='item'>
              <h2>Manejo ERP Flexline, SAP, Buk, Starter y Softland, Office, Conocimiento bases de datos y SQL.</h2>
            </li>
          </ol>
        </div>
    </div>
 
  )
}

export default Bienvenida
import Proj1 from '../assets/proj1.jpg'
import Proj2 from '../assets/proj2.jpg'
import Proj3 from '../assets/proj3.jpg'
import Proj4 from '../assets/proj4.jpg'
import Proj5 from '../assets/proj5.jpg'
import Proj6 from '../assets/proj6.jpg'
import Proj7 from '../assets/proj7.jpg'
export const ProjectList = [
    {
        name:"Paridad de género en los directorios",
        image:Proj1,
        info:"Subsecretaria de Economía visitó La Araucana en el marco del Proyecto de Ley que promueve la Paridad de Género en los Directorios ",
        url:"https://www.laaraucana.cl/subsecretaria-de-economia-visito-la-araucana-en-el-marco-del-proyecto-de-ley-que-promueve-la-paridad-de-genero-en-los-directorios/",
    },
    {
        name:"Directorio en Regiones",
        image:Proj2,
        info:"La Araucana sella alianza con distintas empresas de la región de Valparaíso para promover economía local",
        url:"https://g5noticias.cl/2022/12/28/la-araucana-sella-alianza-con-distintas-empresas-de-la-region-de-valparaiso-para-promover-economia-local/",
    },
    {
        name:"Premio al espiritu trabajadores La Araucana",
        image:Proj3,
        info:"Trabajadores y trabajadoras de distintas ciudades reciben premio “Espíritu La Araucana”",
        url:"https://www.laaraucana.cl/trabajadores-y-trabajadoras-de-distintas-ciudades-reciben-premio-espiritu-la-araucana/",
    },
    {
        name:"Programa de Reconocimiento Educacional Zona Sur",
        image:Proj4,
        info:"Programa de Reconocimiento Educacional de La Araucana beneficiará con becas a 2.218 estudiantes de la zona sur del país",
        url:"https://araucanianoticias.cl/2022/programa-de-reconocimiento-educacional-de-la-araucana-beneficiar-con-becas-a-2-218-estudiantes-de-la-zona-sur-del-pas/0915221897/amp",
    },
    {
        name:"Convenios y beneficios CCAF La Araucana",
        image:Proj5,
        info:"Conoce el detalle de convenios y beneficios que la Caja tiene disponible para sus afiliados",
        url:"https://convenios.laaraucana.cl/",
    },
    {
        name:"Postulación La Araucana",
        image:Proj6,
        info:"Profesional de FUDEA UFRO postula a directora laboral nacional de Caja la Araucana",
        url:"https://www.fudeaufro.cl/profesional-de-fudea-ufro-postula-a-directora-laboral-nacional-de-caja-la-araucana/",
    },
    {
        name:"Reelección Caja La Araucana",
        image:Proj7,
        info:"Profesional de FUDEA UFRO electa como Directora Laboral Nacional de Caja la Araucana",
        url:"https://www.fudeaufro.cl/profesional-de-fudea-ufro-fue-electa-entre-los-directores-laborales-nacionales-de-caja-la-araucana/",
    },

  

];
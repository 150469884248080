import React, { useEffect, useState } from 'react'
import {Link, useLocation} from 'react-router-dom'
import '../styles/Navbar.css'
import ReorderIcon from '@mui/icons-material/Reorder';
function Nabvar() {
    const[expandNavbar, setExpandNavbar] = useState(false);
    const location = useLocation();
    useEffect(()=>{
        setExpandNavbar(false);
    },[location]);
  return (
    <div className='navbar' id={expandNavbar ? "open":"close"}> 
        <div className='toggleButton'>
            <button onClick={()=>{
                setExpandNavbar((prev)=>!prev);
                }}
                >
                
                <ReorderIcon/> 
               </button>
        </div>
        <div className='links'>
            <Link to='/'>Bienvenida</Link>
            <Link to='/Experiencia'>Experiencia</Link>
            <Link to='/Perfil'>Perfil</Link>
            <Link to='/Proyectos'>Actividades</Link>
        </div>
     
    </div>
  )
}

export default Nabvar
import React from 'react'
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import '../styles/Footer.css'
function Footer() {
  return (
    <div className='footer'>
        <div className='socialMedia'>
            <WhatsAppIcon onClick={() => window.open('https://wa.me/message/UJ6NGJK4BFA4N1', '_blank')}/>
            <LinkedInIcon onClick={() => window.open('https://www.linkedin.com/in/lilian-sanhueza-4b26a372/', '_blank')} />
        </div>
        <p>&copy; 2024 Lilian Sanhueza</p>
    </div>
  )
}

export default Footer